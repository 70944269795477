<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-row
        align="center"
        class="my-0"
        :class="hover ? 'green lighten-5' : ''"
      >
        <v-col cols="2" class="pl-2"
          ><v-avatar size="30" class="mr-4">
            <v-icon>mdi-propane-tank</v-icon>
          </v-avatar>
          <b>{{ tank.nome }}</b>
          <v-icon class="ml-2" v-if="isConnected" color="green"
            >mdi-link</v-icon
          >
          <v-tooltip v-else top>
            <template v-slot:activator="{ on }">
              <v-icon class="ml-2" v-on="on" color="red">mdi-link-off</v-icon>
            </template>
            {{ last_update }}
          </v-tooltip>
        </v-col>
        <v-col cols="2">{{ nome_tipo }} </v-col>
        <v-col cols="1">{{ capacit.toFixed(0) }} Litri</v-col>
        <v-col cols="1">
          <b>{{ tank.temp_lettura.toFixed(1) }}</b> °C
        </v-col>
        <v-col cols="1">
          <b>{{ tank.altezza_liquido.toFixed(0) }}</b> cm</v-col
        >
        <v-col cols="1">
          <b>{{ tank.volume_liquido.toFixed(0) }}</b> Litri</v-col
        >
        <v-col cols="3">
          <v-progress-linear
            :value="percentuale_riemp"
            height="30"
            color="indigo"
          >
            <span class="white--text"
              ><strong>{{ percentuale_riemp.toFixed(0) }} %</strong></span
            >
          </v-progress-linear>
        </v-col>
        <v-col cols="1">
          <TankGraph :tank="tank" />
          <v-dialog v-model="dialog" width="600">
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on" v-show="hover" color="blue">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="">
                Modifica Parametri Cisterna
              </v-card-title>
              <v-card-text>
                <v-text-field outlined label="Nome" v-model="nome" />
                <v-select
                  outlined
                  label="Tipo"
                  v-model="tipo"
                  :items="tanks_type"
                  item-value="value"
                  item-text="text"
                ></v-select>
                <v-text-field outlined label="Altezza" v-model="altezza" />
                <template v-if="tipo == 1">
                  <v-text-field
                    outlined
                    label="Larghezza"
                    v-model="larghezza"
                  />
                  <v-text-field outlined label="Profondità" v-model="profond" />
                </template>
                <template v-else>
                  <v-text-field outlined label="Diametro" v-model="diametro" />
                </template>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog_elimina" width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn outlined color="red" v-on="on"
                      ><v-icon class="mr-2">mdi-delete</v-icon>Elimina</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title class="red--text">Elimina</v-card-title>
                    <v-card-text> Sei sicuro ? </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn outlined color="red" @click="delete_tank()"
                        ><v-icon class="mr-2">mdi-delete</v-icon>Elimina</v-btn
                      >
                      <v-btn text color="grey" @click="dialog_elimina = false"
                        >Annulla</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn text color="grey" @click="dialog = false"> OK </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-hover>
    <v-divider />
  </div>
</template>

<script>
import tank_element_mixin from "../mixin/tank_element_mixin";
// import _ from "lodash";
// import moment from "moment";
import TankGraph from "./tank_graph_desktop.vue";
export default {
  components: {
    TankGraph,
  },
  mixins: [tank_element_mixin],
  // props: {
  //   tank: Object,
  // },
  // mounted() {
  //   this.debounceNome = _.debounce(this.set_nome, 2000);
  //   this.debounceAltezza = _.debounce(this.set_altezza, 2000);
  //   this.debounceLarghezza = _.debounce(this.set_larghezza, 2000);
  //   this.debounceProfond = _.debounce(this.set_profond, 2000);
  //   this.debounceDiametro = _.debounce(this.set_diametro, 2000);
  // },
  // data() {
  //   return {
  //     hover: false,
  //     dialog: false,
  //     nome: this.tank.nome,
  //     tipo: this.tank.type,
  //     altezza: this.tank.altezza,
  //     diametro: this.tank.diametro,
  //     larghezza: this.tank.larghezza,
  //     profond: this.tank.profond,
  //     tanks_type: [
  //       { value: 1, text: "Cubica" },
  //       { value: 2, text: "Cilindrica" },
  //       { value: 3, text: "Cilindrica Coricata" },
  //     ],
  //   };
  // },
  // watch: {
  //   nome: function () {
  //     this.debounceNome();
  //   },
  //   tipo: function () {
  //     this.set_tipo();
  //   },
  //   altezza: function () {
  //     this.debounceAltezza();
  //   },
  //   larghezza: function () {
  //     this.debounceLarghezza();
  //   },
  //   profond: function () {
  //     this.debounceProfond();
  //   },
  //   diametro: function () {
  //     this.debounceDiametro();
  //   },
  // },
  // computed: {
  //   nome_tipo() {
  //     return this.tanks_type.find((tank) => tank.value == this.tank.type).text;
  //   },
  //   capacit() {
  //     if (this.tank.type == 0) {
  //       return 0;
  //     } else if (this.tank.type == 1) {
  //       return this.tank.altezza * this.tank.larghezza * this.tank.profond;
  //     } else {
  //       return (
  //         (Math.pow(this.tank.diametro / 2, 2) * 3.14 * this.tank.altezza) /
  //         1000
  //       );
  //     }
  //   },
  //   percentuale_riemp() {
  //     return (this.tank.volume_liquido / this.capacit) * 100;
  //   },
  //   isConnected() {
  //     return (
  //       this.tank.last_update > moment().subtract(5, "minutes").format("X")
  //     );
  //   },
  // },
  // methods: {
  //   set_nome() {
  //     this.$store.dispatch("cambia_nome_tank", {
  //       id_tank: this.tank._id.$oid,
  //       nome: this.nome,
  //     });
  //   },
  //   set_tipo() {
  //     this.$store.dispatch("cambia_tipo_tank", {
  //       id_tank: this.tank._id.$oid,
  //       tipo: this.tipo,
  //     });
  //   },
  //   set_altezza() {
  //     this.$store.dispatch("cambia_altezza_tank", {
  //       id_tank: this.tank._id.$oid,
  //       altezza: this.altezza,
  //     });
  //   },
  //   set_larghezza() {
  //     this.$store.dispatch("cambia_larghezza_tank", {
  //       id_tank: this.tank._id.$oid,
  //       larghezza: this.larghezza,
  //     });
  //   },
  //   set_profond() {
  //     this.$store.dispatch("cambia_profond_tank", {
  //       id_tank: this.tank._id.$oid,
  //       profond: this.profond,
  //     });
  //   },
  //   set_diametro() {
  //     this.$store.dispatch("cambia_diametro_tank", {
  //       id_tank: this.tank._id.$oid,
  //       diametro: this.diametro,
  //     });
  //   },
  // },
};
</script>