<template>
  <v-card>
    <v-card-title class="grey--text text-subtitle-1">Cisterne</v-card-title>
    <v-card-text>
      <v-row v-if="!loading">
        <v-col cols="2">Nome</v-col>
        <v-col cols="2">Tipo</v-col>
        <v-col cols="1">Capacità</v-col>
        <v-col cols="1">Temperatura</v-col>
        <v-col cols="1">Livello Attuale</v-col>
        <v-col cols="2">Volume Attuale</v-col>
        <v-col cols="2"></v-col>
      </v-row>
      <v-row v-else justify="center" align="center">
        <v-progress-circular
          indeterminate
          width="1"
          color="green"
        ></v-progress-circular>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <TankElement v-for="tank in tanks" :key="tank.id" :tank="tank" />
    </v-card-text>
  </v-card>
</template>

<script>
import TankElement from "./tank_desktop_element.vue";
import tank_mixin from "../mixin/tank_main_mixin";
export default {
  components: {
    TankElement,
  },
  mixins: [tank_mixin],
};
</script>
