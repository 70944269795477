<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-icon>mdi-propane-tank</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ tank.nome }}
      </v-list-item-title>
      <v-list-item-subtitle>
        <span v-if="isConnected" class="green--text">Online</span>
        <span v-if="!isConnected" class="red--text">Offline ({{last_update}})</span> 
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        Capacità: {{capacit.toFixed(0)}} Litri
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <v-progress-linear
          :value="percentuale_riemp"
          height="20"
          color="indigo"
        >
          <span class="white--text"
            ><strong>{{ percentuale_riemp.toFixed(0) }} %</strong></span
          >
        </v-progress-linear>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action-text class="ml-6">
        <span style="font-size: 20px">{{ tank.volume_liquido.toFixed(0) }}</span> Lit.
        <br>
        <span style="font-size: 14px">{{tank.altezza_liquido}}</span> Cm
        <br>
        <span style="font-size: 14px">{{tank.temp_lettura.toFixed(1)}}</span> °c
    </v-list-item-action-text>
  </v-list-item>
</template>

<script>
import tank_el_mixin from "../mixin/tank_element_mixin";
export default {
  mixins: [tank_el_mixin],
};
</script>