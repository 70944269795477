import _ from "lodash";
import moment from "moment";
export default {
  props: {
    tank: Object,
  },
  mounted() {
    this.debounceNome = _.debounce(this.set_nome, 2000);
    this.debounceAltezza = _.debounce(this.set_altezza, 2000);
    this.debounceLarghezza = _.debounce(this.set_larghezza, 2000);
    this.debounceProfond = _.debounce(this.set_profond, 2000);
    this.debounceDiametro = _.debounce(this.set_diametro, 2000);
  },
  data() {
    return {
      hover: false,
      dialog: false,
      nome: this.tank.nome,
      tipo: this.tank.type,
      altezza: this.tank.altezza,
      diametro: this.tank.diametro,
      larghezza: this.tank.larghezza,
      profond: this.tank.profond,
      tanks_type: [
        { value: 1, text: "Cubica" },
        { value: 2, text: "Cilindrica" },
        { value: 3, text: "Cilindrica Coricata" },
      ],
      dialog_elimina: false
    };
  },
  watch: {
    nome: function () {
      this.debounceNome();
    },
    tipo: function () {
      this.set_tipo();
    },
    altezza: function () {
      this.debounceAltezza();
    },
    larghezza: function () {
      this.debounceLarghezza();
    },
    profond: function () {
      this.debounceProfond();
    },
    diametro: function () {
      this.debounceDiametro();
    },
  },
  computed: {
    nome_tipo() {
      return this.tanks_type.find((tank) => tank.value == this.tank.type).text;
    },
    capacit() {
      if (this.tank.type == 0) {
        return 0;
      } else if (this.tank.type == 1) {
        return this.tank.altezza * this.tank.larghezza * this.tank.profond;
      } else {
        return (
          (Math.pow(this.tank.diametro / 2, 2) * 3.14 * this.tank.altezza) /
          1000
        );
      }
    },
    percentuale_riemp() {
      return (this.tank.volume_liquido / this.capacit) * 100;
    },
    isConnected() {
      return (
        this.tank.last_update > moment().subtract(5, "minutes").format("X")
      );
    },
    last_update(){
      return moment.unix(this.tank.last_update).fromNow()
    }
  },
  methods: {
    set_nome() {
      this.$store.dispatch("cambia_nome_tank", {
        id_tank: this.tank._id.$oid,
        nome: this.nome,
      });
    },
    set_tipo() {
      this.$store.dispatch("cambia_tipo_tank", {
        id_tank: this.tank._id.$oid,
        tipo: this.tipo,
      });
    },
    set_altezza() {
      this.$store.dispatch("cambia_altezza_tank", {
        id_tank: this.tank._id.$oid,
        altezza: this.altezza,
      });
    },
    set_larghezza() {
      this.$store.dispatch("cambia_larghezza_tank", {
        id_tank: this.tank._id.$oid,
        larghezza: this.larghezza,
      });
    },
    set_profond() {
      this.$store.dispatch("cambia_profond_tank", {
        id_tank: this.tank._id.$oid,
        profond: this.profond,
      });
    },
    set_diametro() {
      this.$store.dispatch("cambia_diametro_tank", {
        id_tank: this.tank._id.$oid,
        diametro: this.diametro,
      });
    },
    delete_tank(){
      this.$store.dispatch("elimina_tank", this.tank._id.$oid)
    }
  },
};
