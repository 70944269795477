var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{staticClass:"my-0",class:hover ? 'green lighten-5' : '',attrs:{"align":"center"}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"2"}},[_c('v-avatar',{staticClass:"mr-4",attrs:{"size":"30"}},[_c('v-icon',[_vm._v("mdi-propane-tank")])],1),_c('b',[_vm._v(_vm._s(_vm.tank.nome))]),(_vm.isConnected)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"green"}},[_vm._v("mdi-link")]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"color":"red"}},on),[_vm._v("mdi-link-off")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.last_update)+" ")])],1),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(_vm._s(_vm.nome_tipo)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(_vm._s(_vm.capacit.toFixed(0))+" Litri")]),_c('v-col',{attrs:{"cols":"1"}},[_c('b',[_vm._v(_vm._s(_vm.tank.temp_lettura.toFixed(1)))]),_vm._v(" °C ")]),_c('v-col',{attrs:{"cols":"1"}},[_c('b',[_vm._v(_vm._s(_vm.tank.altezza_liquido.toFixed(0)))]),_vm._v(" cm")]),_c('v-col',{attrs:{"cols":"1"}},[_c('b',[_vm._v(_vm._s(_vm.tank.volume_liquido.toFixed(0)))]),_vm._v(" Litri")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-progress-linear',{attrs:{"value":_vm.percentuale_riemp,"height":"30","color":"indigo"}},[_c('span',{staticClass:"white--text"},[_c('strong',[_vm._v(_vm._s(_vm.percentuale_riemp.toFixed(0))+" %")])])])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('TankGraph',{attrs:{"tank":_vm.tank}}),_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],attrs:{"icon":"","small":"","color":"blue"}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{},[_vm._v(" Modifica Parametri Cisterna ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","label":"Nome"},model:{value:(_vm.nome),callback:function ($$v) {_vm.nome=$$v},expression:"nome"}}),_c('v-select',{attrs:{"outlined":"","label":"Tipo","items":_vm.tanks_type,"item-value":"value","item-text":"text"},model:{value:(_vm.tipo),callback:function ($$v) {_vm.tipo=$$v},expression:"tipo"}}),_c('v-text-field',{attrs:{"outlined":"","label":"Altezza"},model:{value:(_vm.altezza),callback:function ($$v) {_vm.altezza=$$v},expression:"altezza"}}),(_vm.tipo == 1)?[_c('v-text-field',{attrs:{"outlined":"","label":"Larghezza"},model:{value:(_vm.larghezza),callback:function ($$v) {_vm.larghezza=$$v},expression:"larghezza"}}),_c('v-text-field',{attrs:{"outlined":"","label":"Profondità"},model:{value:(_vm.profond),callback:function ($$v) {_vm.profond=$$v},expression:"profond"}})]:[_c('v-text-field',{attrs:{"outlined":"","label":"Diametro"},model:{value:(_vm.diametro),callback:function ($$v) {_vm.diametro=$$v},expression:"diametro"}})]],2),_c('v-card-actions',[_c('v-spacer'),_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","color":"red"}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-delete")]),_vm._v("Elimina")],1)]}}],null,true),model:{value:(_vm.dialog_elimina),callback:function ($$v) {_vm.dialog_elimina=$$v},expression:"dialog_elimina"}},[_c('v-card',[_c('v-card-title',{staticClass:"red--text"},[_vm._v("Elimina")]),_c('v-card-text',[_vm._v(" Sei sicuro ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"red"},on:{"click":function($event){return _vm.delete_tank()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-delete")]),_vm._v("Elimina")],1),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.dialog_elimina = false}}},[_vm._v("Annulla")])],1)],1)],1),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)]}}])}),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }