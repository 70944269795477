<template>
  <v-list> 
    <TankElement v-for="tank in tanks" :key="tank._id.$oid" :tank="tank"/>
  </v-list>
</template>

<script>
import TankElement from "./tank_mobile_element.vue";
import tank_mixin from "../mixin/tank_main_mixin";
export default {
  components: {
    TankElement,
  },
  mixins: [tank_mixin],
};
</script>