export default {
  mounted() {
    this.loading = true;
    this.$store.dispatch("fetch_tanks").then(() => {
      this.loading = false;
    });
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    tanks() {
      return this.$store.getters.get_tanks;
    },
  },
};
