<template>
  <v-dialog v-model="dialog" width="1200">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" color="purple"
        ><v-icon>mdi-chart-line</v-icon></v-btn
      >
    </template>
    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        <v-row align="center" class="">
          <v-col cols="2"> Andamento </v-col>
          <v-col cols="4">
            <v-select
              v-model="intervallo_sel"
              label="intervallo"
              hide-details
              outlined
              clear-icon=""
              dense
              :items="intervalli"
              item-value="value"
              item-text="text"
            ></v-select>
          </v-col>
        </v-row>
        <v-spacer />
        <v-btn text color="grey" @click="dialog = false">chiudi</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row v-if="loading" fill-height align="center" justify="center"
          ><v-progress-circular
            indeterminate
            color="green"
          ></v-progress-circular
        ></v-row>
        <v-row v-else>
          <v-col cols="12" class="mt-2">
            <VueApexCharts
              class="mt-n2"
              type="area"
              :options="options"
              :series="series"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    tank: Object,
  },
  data() {
    return {
      dialog: false,
      loading: true,
      char_data: [],
      intervalli: [
        { text: "1 giorno", value: 24 },
        { text: "2 giorni", value: 48 },
        { text: "3 giorni", value: 72 },
        { text: "7 giorni", value: 168 },
        { text: "15 giorni", value: 360 },
      ],
      intervallo_sel: 24,
      options: {
        chart: {
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        // tooltip: {
        //   y: {
        //     formatter: (val) => {
        //       return val;
        //     },
        //   },
        // },
        // grid: {
        //   show: true,
        // },
        // legend: {
        //   show: false,
        // },
        // dataLabels: {
        //   enabled: false,
        // },
        xaxis: {
          type: "datetime",
          labels: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: [
          {
            min: 0,
            max: this.tank.volume_totale,
            tickAmount: 20,
            labels: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: true,
            },
          },
          {
            opposite: true,
            min: -10,
            max: 30,
            tickAmount: 20,
            labels: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: true,
            },
          },
          {
            opposite: true,
            min: 0,
            max: 500,
            tickAmount: 20,
            labels: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: true,
            },
          },
        ],
        // colors: ["#448AFF", "#00E676"],
        stroke: {
          curve: ["smooth", "smooth", "smooth"],
          width: [2, 1],
          colors: ["#3F51B5", "#FF5722", "#00E676"],
        },
        fill: {
          type: ["gradient"],
          colors: ["#1A73E8", "#FF9800"],
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.8,
            opacityTo: 0.1,
            stops: [0, 100],
          },
        },
      },
    };
  },
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("fetch_tank_chart_data", {
        id_tank: this.tank._id.$oid,
        ts_inizio: moment().subtract(this.intervallo_sel, "hours").format("X"),
        ts_fine: moment().format("X"),
      })
      .then((res) => {
        console.log(res);
        this.char_data = res;
        this.loading = false;
      });
  },
  watch: {
    intervallo_sel: function () {
      console.log(moment().subtract(this.intervallo_sel, "hours").format("X"));
      //this.loading = true;
      this.$store
        .dispatch("fetch_tank_chart_data", {
          id_tank: this.tank._id.$oid,
          ts_inizio: moment()
            .subtract(this.intervallo_sel, "hours")
            .format("X"),
          ts_fine: moment().format("X"),
        })
        .then((res) => {
          console.log(res);
          this.char_data = res;
          //this.loading = false;
        });
    },
  },
  computed: {
    series() {
      return [
        {
          type: "area",
          name: "Litri",
          data: this.char_data
            .reduce((acc, curr, index) => {
              const gruppo = Math.floor(index / 5);
              if (!acc[gruppo]) {
                acc[gruppo] = {
                  timestamp: curr.timestamp * 1000, // Memorizza il timestamp del primo oggetto di ogni gruppo
                  values: [],
                };
              }
              acc[gruppo].values.push(curr.value);
              return acc;
            }, [])
            .map((gruppo) => {
              const media =
                gruppo.values.reduce((sum, value) => sum + value, 0) /
                gruppo.values.length;
              return [gruppo.timestamp, media.toFixed(1)];
            }),
          // data: this.char_data.map((item) => {
          //   return [item.timestamp * 1000, parseInt(item.value)];
          // }),
        },
        {
          type: "line",
          name: "Gradi",
          data: this.char_data
            .reduce((acc, curr, index) => {
              const gruppo = Math.floor(index / 5);
              if (!acc[gruppo]) {
                acc[gruppo] = {
                  timestamp: curr.timestamp * 1000, // Memorizza il timestamp del primo oggetto di ogni gruppo
                  values: [],
                };
              }
              acc[gruppo].values.push(curr.temperatura);
              return acc;
            }, [])
            .map((gruppo) => {
              const media =
                gruppo.values.reduce((sum, value) => sum + value, 0) /
                gruppo.values.length;
              return [gruppo.timestamp, media.toFixed(1)];
            }),
        },
        {
          type: "line",
          name: "Raw",
          data: this.char_data
            .reduce((acc, curr, index) => {
              const gruppo = Math.floor(index / 5);
              if (!acc[gruppo]) {
                acc[gruppo] = {
                  timestamp: curr.timestamp * 1000, // Memorizza il timestamp del primo oggetto di ogni gruppo
                  values: [],
                };
              }
              acc[gruppo].values.push(curr.lettura_raw);
              return acc;
            }, [])
            .map((gruppo) => {
              const media =
                gruppo.values.reduce((sum, value) => sum + value, 0) /
                gruppo.values.length;
              return [gruppo.timestamp, media.toFixed(1)];
            }),
        },
        // {
        //   name: "Livello",
        //   data: this.char_data.map((item) => {
        //     return [item.timestamp * 1000, item.altezza_liquido];
        //   }),
        // },
      ];
    },
  },
};
</script>