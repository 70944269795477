<template>
  <TanksDesktop v-if="!$vuetify.breakpoint.mobile" />
  <TankMobile v-else/>
</template>

<script>
import TanksDesktop from "@/components/Tanks/Desktop/tanks_desktop_main";
import TankMobile from "@/components/Tanks/Mobile/tank_mobile_main"
export default {
  components: {
    TanksDesktop,
    TankMobile
  },
};
</script>
